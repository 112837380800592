import React, { useState } from 'react'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber , onAuthStateChanged} from 'firebase/auth';
import { initializeApp } from "firebase/app";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PhoneAuth = () => {
  const config = {
    apiKey: "AIzaSyCawy4CmRCXe9SezqUkBygbNEUiK4hY6aM",
    authDomain: "prativadotin.firebaseapp.com",
    projectId: "prativadotin",
    storageBucket: "prativadotin.appspot.com",
    messagingSenderId: "573645084496",
    appId: "1:573645084496:web:52ce0c90fe02078774be55",
    measurementId: "G-M53Y61NLVJ"
  };
  const app = initializeApp(config);
  const authentication = getAuth(app);
  const [phone, setphone] = useState('+91')
  const [OTP, setOTP] = useState('');
  const [expanded, setexpanded] = useState(false);


  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    }, authentication);
  }

  const requestOtp = (e) => {
    e.preventDefault();

    if (phone.length >= 12) {
      setexpanded(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, "+" + phone, appVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      }).catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error);
      });

    }
  }

  const verifyOtp = (e) => {
    let otpi = e.target.value;
    setOTP(otpi);
    if (otpi.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otpi).then((result) => {
        // User signed in successfully.
        // const user = result.user;

        toast.success('🔥 Successfully Logged In', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // ...
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        alert(error);
        // ...
      });
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
      <div style={{ display: "flex", background: "#8058FF" , flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <form onSubmit={requestOtp} style={{background: "white" , display: "flex" , flexDirection: "column" , alignItems: "center" , justifyContent:"center", width: "350px" , height: "380px" , borderRadius: "12px" , boxShadow: "10px 10px 20px 0.5 black"}}>
          <div style={{ marginLeft: "10px", padding: "0 20px", alignItems: "center" }}>
            <label htmlFor='phone' style={{ fontWeight: "600" , color: "black" }}>Enter Your Phone Number</label> <br /><br />
            {/* <input style={{padding: '10px 10px' , borderRadius: '10px' , marginLeft: '20px'}} value={phone} onChange={e=>setphone(e.target.value)} type={"tel"} id="phone" /> */}
            <PhoneInput
              searchStyle={{color: "black" , padding: "8px 12px" , fontWeight: "bold"}}
              dropdownStyle={{color: "black"}}
              country={"eg"}
              enableSearch={true}
              value={phone}
              countryCodeEditable={false}
              onChange={(phone) => setphone(phone)}
            />
          </div>
          {
            expanded === true ?
              <>
                <div style={{ marginLeft: "10px" }}>
                  {/* <label htmlFor='opt' style={{ fontWeight: "600" }}></label> */}
                  <input value={OTP} onChange={verifyOtp} style={{ padding: '12px 18px', fontWeight:"600" , outline: "none", borderRadius: '6px', border:"2px solid grey" , fontSize: "16px", marginLeft: '2px', marginTop: '30px' }} type={"number"} id="otp" placeholder='Enter Your OTP' color='black' />
                  {/* <div style={{ color: 'red', margin: '20px 0' }}>Please enter one time pin send to your phone</div> */}
                </div>
              </>
              : null
          }
          {
            expanded === false ?
              <button style={{ padding: '10px 10px', color: "white", fontWeight: "700", width: "50%", margin: '30px 25%', marginTop: '20px', borderRadius: '9px', background: '#1565C0', border: "none" }} type='submit' onClick={() => {
                toast.success('✅ OTP Sent', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }} >Request Otp</button>
              : null
          }
          <div id='recaptcha-container'></div>
        </form>
      </div>
    </div>
  )
}

export default PhoneAuth;